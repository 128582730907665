import React, { useEffect, useRef, useState } from "react";

import SendOutlinedIcon from "@mui/icons-material/SendOutlined";
import Avatar from "@mui/material/Avatar";
import { green, blue, blueGrey } from "@mui/material/colors";
import ReactMarkdown from "react-markdown";
import { toast } from "react-toastify";
import "../general/GenericAI.css";
import url from "../../config/url.json";
import captions from "../../config/captions.json";
import {
  Stack,
  Box,
  FormControl,
  OutlinedInput,
  CircularProgress,
  IconButton,
  Button,
  ButtonGroup,
  Typography,
} from "@mui/material";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import { useTheme } from "@mui/system";
import PDFPopup from "../../components/PDFPopup";
import axios from "axios";
import { useSelector } from "react-redux";

const messageType = {
  answer: "assistant",
  question: "user",
};

const GenericAI = (props) => {
  const inputRef = useRef();
  const chatWrapperRef = useRef();
  const [userName, setUserName] = useState("");
  const [onRequest, setOnRequest] = useState(false);
  const [question, setQuestion] = useState("");
  const [messages, setMessages] = useState([]);
  //const [linkText, setLinkText] = useState(null);
  const [pdfUrl, setPdfUrl] = useState(null);
  const theme = useTheme();
  const isDarkMode = useSelector((state) => state.darkMode);

  const org_icon = require("../../images/org.png");

  const handleOpenPopup = (url, p) => {
    setPdfUrl(url);
  };

  const handleClosePopup = () => {
    setPdfUrl(null);
  };

  const captionButtons = (id) => {
    let topics;
    switch (id) {
      case "policy":
        topics = captions.org.boxCaptionsPolicy;
        break;
      case "quest":
        topics = captions.org.boxCaptionsQuest;
        break;
      default:
        break;
    }
    return topics.map((item) => (
      <Button
        component="label"
        style={{
          textTransform: "none",
          margin: "2px",
          boxShadow:
            "0px 3px 3px -1px rgba(0,0,0,0.1), 0px 6px 3px 0px rgba(0,0,0,0.04), 0px 1px 5px 0px rgba(0,0,0,0.06)",
        }}
        sx={{
          "&:hover": {
            backgroundColor: "#b2c6e2",
            color: isDarkMode ? "black" : "info", // Change to your desired hover color
          },
        }}
        onClick={() => getCaptionsAnswer(item)}
      >
        {item}
      </Button>
    ));
  };

  const getPdf = async (pdfName) => {
    const api_url = url.dev.baseUrl + url.dev.getPdf;
    const queryParams = {
      citation: pdfName,
    };

    try {
      const response = await axios.get(api_url, {
        params: queryParams,
        responseType: "arraybuffer",
      });

      if (response.status === 200) {
        const blob = new Blob([response.data], { type: "application/pdf" });
        const pdfUrl = URL.createObjectURL(blob);

        setPdfUrl(pdfUrl);
        handleOpenPopup(pdfUrl, "a");
      } else {
        toast.error("Something went wrong", {
          duration: 1000,
          position: "bottom-left",
        });

        setOnRequest(false);
      }
    } catch (err) {
      toast.error("Something went wrong", {
        duration: 1000,
        position: "bottom-left",
      });

      setOnRequest(false);
      return { err };
    }
  };

  const getCaptionsAnswer = async (qData) => {
    if (onRequest) return;

    const newMsgs = [
      ...messages,
      {
        role: messageType.question,
        content: qData,
        refs: [],
      },
    ];

    setMessages(newMsgs);

    setQuestion("");
    setOnRequest(true);

    const api_url = url.dev.baseUrl + url.dev.orgAI;

    try {
      const response = await fetch(api_url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ prompt: qData }),
      });

      if (response.status === 200) {
        const decoder = new TextDecoder();
        const reader = response.body.getReader();
        var chunks = "";
        var refLinks = [];
        var refs = "";
        while (true) {
          const { done, value } = await reader.read();
          if (done) {
            if (chunks.includes("ae29c252d82d46179550e461ed66ebbc")) {
              var splitArr = chunks.split("ae29c252d82d46179550e461ed66ebbc");
              chunks = splitArr[0];
              refs = splitArr[1];
              refLinks = JSON.parse(refs);

              setMessages([
                ...newMsgs,
                {
                  role: messageType.answer,
                  content: chunks,
                  refNames: refLinks.citations,
                },
              ]);
            }

            setOnRequest(false);
            break;
          }

          chunks += decoder.decode(value);

          setMessages([
            ...newMsgs,
            {
              role: messageType.answer,
              content: chunks.replace(/^None/, "").trim(),
              refNames: [],
            },
          ]);
        }
      } else {
        toast.error("Something went wrong", {
          duration: 1000,
          position: "bottom-left",
        });
        setOnRequest(false);
      }
    } catch (err) {
      toast.error("Something went wrong", {
        duration: 1000,
        position: "bottom-left",
      });

      setOnRequest(false);
      return { err };
    }
  };

  const onEnterPress = (e) => {
    if (e.keyCode === 13 && question !== "") getCaptionsAnswer(question);
  };
  const nameFormat = () => {
    var firstLetter = sessionStorage.getItem("name").split(" ")[0][0];
    var secondLetter = sessionStorage.getItem("name").split(" ")[1];
    if (secondLetter) {
      firstLetter = firstLetter + secondLetter[0];
    }

    return firstLetter;
  };
  useEffect(() => {
    if (inputRef.current && !onRequest) {
      inputRef.current.focus();
      inputRef.current.select();
    }
    if (props.newChatFromDrawer) {
      setMessages([]);
      props.updateFromChild(false);
    }
    setUserName(
      sessionStorage.getItem("name") === null ||
        sessionStorage.getItem("name") === "undefined"
        ? ""
        : sessionStorage.getItem("name")
    );
    if (chatWrapperRef.current) {
      if (chatWrapperRef.current) {
        chatWrapperRef.current.scrollTop = chatWrapperRef.current.scrollHeight;
      }
    }
  }, [props, userName, messages, onRequest]);

  return (
    <React.Fragment>
      <Stack
        alignItems="center"
        direction={"column"}
        justifyContent="space-between"
        sx={{ height: "100%", width: "100%" }}
      >
        <Box
          ref={chatWrapperRef}
          sx={{
            height:
              window.innerHeight < 700
                ? "58vh"
                : window.innerWidth >= 400 && window.innerWidth <= 450
                ? "70vh"
                : "65vh",
            maxHeight: "68vh",
            minHeight: "60vh",
            position: "fixed",
            zIndex: 1,
            justifyContent: "center",
            maxWidth:
              window.innerWidth >= 600 && window.innerWidth <= 900
                ? "sm"
                : "md",
            width: "100vw",
            overflowY: "auto",

            paddingTop: "10px",
            paddingBottom: "10px",
            paddingLeft: "10px",
            paddingRight: "10px",
            "&::-webkit-scrollbar, & *::-webkit-scrollbar": {
              backgroundColor: "#ddd",
              width: "5px",
            },
            "&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb": {
              borderRadius: 8,
              backgroundColor: "#eee",
              minHeight: 24,
              border: "solid #999",
            },
            "&::-webkit-scrollbar-thumb:focus, & *::-webkit-scrollbar-thumb:focus":
              {
                backgroundColor: "#959595",
              },
            "&::-webkit-scrollbar-thumb:active, & *::-webkit-scrollbar-thumb:active":
              {
                backgroundColor: "#959595",
              },
            "&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover":
              {
                backgroundColor: "#959595",
              },
            "&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner": {
              backgroundColor: "#2b2b2b",
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-end",
              maxWidth: "md",
              width: "100%",
              overflow: "auto",
              fontSize: "15px",
            }}
          >
            {messages.length === 0 ? (
              <div>
                <center>
                  <Avatar
                    sx={{
                      //bgcolor: blue[800],
                      width: 62,
                      height: 62,
                      justifyContent: "center",
                      marginTop: "10px",
                      fontSize: "25px",
                      color: "#fff",
                    }}
                  >
                    <img alt="" src={org_icon} width="63" />
                  </Avatar>
                </center>
                <h6 style={{ marginTop: "15px", fontSize: "18px" }}>
                  <center>{captions.org.botText}</center>
                </h6>
                {onRequest ? null : (
                  <center>
                    <ButtonGroup
                      orientation="vertical"
                      aria-label="Vertical button group"
                      variant="text"
                      color="info"
                      sx={{
                        [theme.breakpoints.down("sm")]: {
                          flexDirection: "column", // Change direction on small screens
                        },
                        marginRight: "8px",
                      }}
                    >
                      {captionButtons("policy")}
                    </ButtonGroup>
                    <ButtonGroup
                      orientation="vertical"
                      aria-label="Vertical button group"
                      variant="text"
                      color="info"
                      sx={{
                        [theme.breakpoints.down("sm")]: {
                          flexDirection: "column", // Change direction on small screens
                        },
                        marginLeft: "8px",
                      }}
                    >
                      {captionButtons("quest")}
                    </ButtonGroup>
                  </center>
                )}
              </div>
            ) : (
              messages.map((item, index) => (
                <Box key={index}>
                  <Stack spacing={2} direction={"row"}>
                    {item.role === messageType.answer ? (
                      <Avatar
                        sx={{
                          //bgcolor: blue[800],
                          width: 35,
                          height: 35,
                        }}
                        style={{ marginTop: "15px" }}
                      >
                        <p
                          style={{
                            color: "white",
                            marginTop: "15px",
                            fontSize: "16px",
                          }}
                        >
                          <img alt="" src={org_icon} width="36" />
                        </p>
                      </Avatar>
                    ) : (
                      <Avatar
                        sx={{ bgcolor: green[900], width: 35, height: 35 }}
                        style={{ marginTop: "15px" }}
                      >
                        {userName !== "" ? (
                          <p
                            style={{
                              color: "white",
                              marginTop: "15px",
                              fontSize: "16px",
                            }}
                          >
                            {nameFormat()}
                          </p>
                        ) : null}
                      </Avatar>
                    )}
                    <Box
                      sx={{
                        padding: 1,
                        /* bgcolor: item.type === messageType.answer && "#2f2f2f", */
                        borderRadius: 3,
                      }}
                    >
                      {item.role === messageType.answer ? (
                        <div>
                          <h6 style={{ marginTop: "10px" }}>
                            {captions.org.botName}
                          </h6>
                          <ReactMarkdown>{item.content}</ReactMarkdown>
                          {item.refNames.length !== 0 ? (
                            <p>
                              <MuiAccordion disableGutters elevation={0}>
                                <MuiAccordionSummary
                                  expandIcon={
                                    <ArrowForwardIosSharpIcon
                                      sx={{ fontSize: "0.7rem" }}
                                    />
                                  }
                                  sx={{
                                    flexDirection: "row-reverse",
                                    "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded":
                                      {
                                        transform: "rotate(90deg)",
                                      },
                                    "& .MuiAccordionSummary-content": {
                                      marginLeft: "2px",
                                    },
                                    minHeight: "auto",
                                  }}
                                >
                                  <Typography sx={{}}>
                                    {item.refNames.length} citation
                                  </Typography>
                                </MuiAccordionSummary>
                                <MuiAccordionDetails
                                  sx={{
                                    //paddingTop: "0px",
                                    paddingRight: "5px",

                                    //borderTop: '1px solid rgba(0, 0, 0, .125)',
                                  }}
                                >
                                  <ButtonGroup
                                    orientation="vertical"
                                    aria-label="Vertical button group"
                                    variant="text"
                                    color="info"
                                  >
                                    {item.refNames.map((docName) => (
                                      <div>
                                        <Button
                                          component="label"
                                          size="small"
                                          //disabled={index !== messages.length - 1}
                                          /* sx={{
                                    "&.Mui-disabled": {
                                      color: isDarkMode ? "#BBB" : "#777",
                                    },
                                  }} */
                                          style={{
                                            textTransform: "none",
                                            justifyContent: "flex-start",
                                            //marginTop: "2px",
                                          }}
                                          onClick={() => getPdf(docName)}
                                        >
                                          {docName}
                                        </Button>
                                        {pdfUrl && (
                                          <PDFPopup
                                            pdfName={docName}
                                            pdfUrl={pdfUrl}
                                            isOpen={true}
                                            onRequestClose={handleClosePopup}
                                          />
                                        )}
                                      </div>
                                    ))}
                                  </ButtonGroup>
                                </MuiAccordionDetails>
                              </MuiAccordion>
                            </p>
                          ) : null}
                        </div>
                      ) : (
                        <div>
                          <h6 style={{ marginTop: "10px" }}>You</h6>

                          <ReactMarkdown>{item.content}</ReactMarkdown>
                        </div>
                      )}
                    </Box>
                  </Stack>
                </Box>
              ))
            )}
          </Box>
        </Box>

        <Stack
          width="100%"
          alignItems="center"
          justifyContent="center"
          zIndex={3}
          position="fixed"
          bottom="0"
        >
          <Box
            padding={2}
            width="100%"
            maxWidth="md"
            zIndex={3}
            textAlign={"center"}
          >
            <FormControl fullWidth variant="outlined">
              <OutlinedInput
                inputRef={inputRef}
                sx={{
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderRadius: "8px",
                  },
                  color: blueGrey[500],
                }}
                endAdornment={
                  onRequest ? (
                    <CircularProgress size="1.5rem" />
                  ) : (
                    <IconButton disabled={question == ""}>
                      <SendOutlinedIcon
                        onClick={() => getCaptionsAnswer(question)}
                      />
                    </IconButton>
                  )
                }
                autoFocus
                disabled={onRequest}
                onKeyUp={onEnterPress}
                value={question}
                onChange={(e) => setQuestion(e.target.value)}
                placeholder="Ask something..."
              />
            </FormControl>
          </Box>
        </Stack>
      </Stack>
    </React.Fragment>
  );
};

export default GenericAI;
