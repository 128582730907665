import React, { useEffect, useRef, useState } from "react";
import Avatar from "@mui/material/Avatar";
import { green, blueGrey } from "@mui/material/colors";
import ReactMarkdown from "react-markdown";
import { toast } from "react-toastify";
import "../general/GenericAI.css";
import url from "../../config/url.json";
import captions from "../../config/captions.json";
import { useSelector } from "react-redux";
import {
  Stack,
  Box,
  CircularProgress,
  Button,
  ButtonGroup,
  FormControl,
  OutlinedInput,
  IconButton,
} from "@mui/material";
import SendOutlinedIcon from "@mui/icons-material/SendOutlined";
import { useTheme } from "@mui/system";
const messageType = {
  answer: "assistant",
  question: "user",
};

const ProjectAI = (props) => {
  const inputRef = useRef(null);
  const chatWrapperRef = useRef();
  const [userName, setUserName] = useState("");
  const [onRequest, setOnRequest] = useState(false);
  const [question, setQuestion] = useState("");
  const [messages, setMessages] = useState([]);
  const [topicClick, setTopicClick] = useState(false);
  const [userEmail, setUserEmail] = useState("");
  const [topicName, setTopicName] = useState("");
  const [indexName, setIndexName] = useState("");
  const [examples, setExamples] = useState([]);

  const isDarkMode = useSelector((state) => state.darkMode);
  const theme = useTheme();

  const project_icon = require("../../images/project.png");

  const captionButtons = () => {
    return captions.project.boxCaptionsProject.map((item) => (
      <Button
        component="label"
        style={{
          textTransform: "none",
          marginBottom: "10px",
          marginTop: "10px",
          textAlign: "center",
          boxShadow:
            "0px 3px 3px -1px rgba(0,0,0,0.1), 0px 6px 3px 0px rgba(0,0,0,0.04), 0px 1px 5px 0px rgba(0,0,0,0.06)",
        }}
        sx={{
          "&:hover": {
            backgroundColor: "#b2c6e2",
            color: isDarkMode ? "black" : "info", // Change to your desired hover color
          },
        }}
        onClick={() => {
          setTopicClick(true);
          setTopicName(Object.keys(item)[0]);
          setIndexName(Object.values(item)[0]);
          setExamples(item.examples);
        }}
      >
        {Object.keys(item)[0]}
      </Button>
    ));
  };

  const formatMarkdownComponents = () => {
    return {
      code: ({ node, inline, className, children, ...props }) => {
        if (className === undefined) {
          return (
            <code
              style={{
                color: "#C21E56",
                scrollbarWidth: "none",
              }}
              {...props}
            >
              {children}
            </code>
          );
        } else {
          return (
            <pre
              style={{
                maxWidth: "60vw",
                overflowX: "auto",
                scrollbarWidth: "thin",
                backgroundColor: isDarkMode ? "#222" : "#f0f0f0",
                padding: "10px",
              }}
            >
              <code {...props}>{children}</code>
            </pre>
          );
        }
      },
      h1: ({ node, inline, className, children, ...props }) => {
        return <h6 {...props}>{children}</h6>;
      },
      h2: ({ node, inline, className, children, ...props }) => {
        return <h6 {...props}>{children}</h6>;
      },
      h3: ({ node, inline, className, children, ...props }) => {
        return <h6 {...props}>{children}</h6>;
      },
      h4: ({ node, inline, className, children, ...props }) => {
        return <h6 {...props}>{children}</h6>;
      },
      h5: ({ node, inline, className, children, ...props }) => {
        return <h6 {...props}>{children}</h6>;
      },
    };
  };

  const getCaptionsAnswer = async (prompt, searhIndex) => {
    if (onRequest) return;

    const newMsgs = [
      ...messages,
      {
        role: messageType.question,
        content: prompt,
      },
    ];
    setMessages(newMsgs);

    setQuestion("");
    setOnRequest(true);
    const api_url = url.dev.baseUrl + url.dev.codeAnalysis;
    try {
      const response = await fetch(api_url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ prompt: prompt, search: searhIndex }),
      });
      if (response.status === 200) {
        const decoder = new TextDecoder();
        const reader = response.body.getReader();
        var chunks = "";
        while (true) {
          const { done, value } = await reader.read();
          if (done) {
            setOnRequest(false);
            break;
          }

          chunks += decoder.decode(value);
          setMessages([
            ...newMsgs,
            {
              role: messageType.answer,
              content: chunks.replace(/^None/, "").trim(),
            },
          ]);
        }
      } else {
        toast.error("Something went wrong", {
          duration: 1000,
          position: "bottom-left",
        });
        setOnRequest(false);
      }
    } catch (err) {
      toast.error("Something went wrong", {
        duration: 1000,
        position: "bottom-left",
      });

      setOnRequest(false);
      return { err };
    }
  };

  const nameFormat = () => {
    var firstLetter = sessionStorage.getItem("name").split(" ")[0][0];
    var secondLetter = sessionStorage.getItem("name").split(" ")[1];
    if (secondLetter) {
      firstLetter = firstLetter + secondLetter[0];
    }

    return firstLetter;
  };
  useEffect(() => {
    if (inputRef.current && !onRequest) {
      inputRef.current.focus();
      inputRef.current.select();
    }
    if (props.newChatFromDrawer) {
      setMessages([]);
      setTopicClick(false);
      setTopicName("");
      setIndexName("");
      setExamples([]);
      props.updateFromChild(false);
    }
    setUserName(
      sessionStorage.getItem("name") === null ||
        sessionStorage.getItem("name") === "undefined"
        ? ""
        : sessionStorage.getItem("name")
    );
    setUserEmail(
      sessionStorage.getItem("email") === null ||
        sessionStorage.getItem("email") === "undefined"
        ? ""
        : sessionStorage.getItem("email")
    );
    if (chatWrapperRef.current) {
      chatWrapperRef.current.scrollTop = chatWrapperRef.current.scrollHeight;
      //chatWrapperRef.current.scrollIntoView({ behaviour: "smooth" });
    }
  }, [props, userName, userEmail, messages, onRequest]);

  const onEnterPress = (e) => {
    if (e.keyCode === 13 && question !== "")
      getCaptionsAnswer(question, indexName);
  };

  return (
    <React.Fragment>
      <Stack
        alignItems="center"
        direction={"column"}
        justifyContent="space-between"
        sx={{ height: "100%", width: "100%" }}
      >
        <Box
          ref={chatWrapperRef}
          sx={{
            height:
              window.innerHeight < 700
                ? "58vh"
                : window.innerWidth >= 400 && window.innerWidth <= 450
                ? "70vh"
                : "65vh",
            maxHeight: "68vh",
            minHeight: "60vh",
            position: "fixed",
            zIndex: 1,
            justifyContent: "center",
            maxWidth:
              window.innerWidth >= 600 && window.innerWidth <= 900
                ? "sm"
                : "md",
            width: "100vw",
            overflowY: "auto",
            paddingTop: "10px",
            paddingBottom: "10px",
            paddingLeft: "10px",
            paddingRight: "10px",
            "&::-webkit-scrollbar, & *::-webkit-scrollbar": {
              backgroundColor: "#ddd",
              width: "5px",
            },
            "&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb": {
              borderRadius: 8,
              backgroundColor: "#eee",
              minHeight: 24,
              border: "solid #999",
            },
            "&::-webkit-scrollbar-thumb:focus, & *::-webkit-scrollbar-thumb:focus":
              {
                backgroundColor: "#959595",
              },
            "&::-webkit-scrollbar-thumb:active, & *::-webkit-scrollbar-thumb:active":
              {
                backgroundColor: "#959595",
              },
            "&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover":
              {
                backgroundColor: "#959595",
              },
            "&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner": {
              backgroundColor: "#2b2b2b",
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-end",
              maxWidth: "md",
              width: "100%",
              overflowX: "auto",
              fontSize: "15px",
              scrollbarWidth: "none",
            }}
          >
            {messages.length === 0 ? (
              <div>
                <center>
                  <Avatar
                    sx={{
                      width: 65,
                      height: 65,
                      justifyContent: "center",
                      marginTop: "25px",
                      fontSize: "25px",
                      color: "#fff",
                    }}
                  >
                    <img alt="" src={project_icon} width="66" />
                  </Avatar>
                </center>
                <h6 style={{ margin: "15px", fontSize: "18px" }}>
                  <center>
                    {topicClick
                      ? topicName + " Source Code Analysis"
                      : captions.project.botText}
                  </center>
                </h6>
                {topicClick ? (
                  <center>
                    <ButtonGroup
                      orientation="vertical"
                      aria-label="Vertical button group"
                      variant="text"
                      color="info"
                      sx={{
                        [theme.breakpoints.down("sm")]: {
                          flexDirection: "column", // Change direction on small screens
                        },
                      }}
                    >
                      {examples.map((item) => (
                        <Button
                          component="label"
                          style={{
                            margin: "2px",
                            textTransform: "none",
                            boxShadow:
                              "0px 3px 3px -1px rgba(0,0,0,0.1), 0px 6px 3px 0px rgba(0,0,0,0.04), 0px 1px 5px 0px rgba(0,0,0,0.06)",
                          }}
                          sx={{
                            "&:hover": {
                              backgroundColor: "#b2c6e2",
                              color: isDarkMode ? "black" : "info", // Change to your desired hover color
                            },
                          }}
                          onClick={() => getCaptionsAnswer(item, indexName)}
                        >
                          {item}
                        </Button>
                      ))}
                    </ButtonGroup>
                  </center>
                ) : (
                  <center>
                    <ButtonGroup
                      orientation="vertical"
                      aria-label="Vertical button group"
                      variant="text"
                      color="info"
                      sx={{
                        [theme.breakpoints.down("sm")]: {
                          flexDirection: "column", // Change direction on small screens
                        },
                      }}
                    >
                      {captionButtons()}
                    </ButtonGroup>
                  </center>
                )}
              </div>
            ) : (
              messages.map((item, index) => (
                <Box key={index}>
                  <Stack spacing={2} direction={"row"}>
                    {item.role === messageType.answer ? (
                      <Avatar
                        sx={{
                          //bgcolor: deepOrange[800],
                          width: 35,
                          height: 35,
                        }}
                        style={{ marginTop: "15px" }}
                      >
                        <p
                          style={{
                            color: "white",
                            marginTop: "15px",
                            fontSize: "16px",
                          }}
                        >
                          <img alt="" src={project_icon} width="36" />
                        </p>
                      </Avatar>
                    ) : (
                      <Avatar
                        sx={{
                          bgcolor: green[900],
                          width: 35,
                          height: 35,
                        }}
                        style={{ marginTop: "15px" }}
                      >
                        {userName !== "" ? (
                          <p
                            style={{
                              color: "white",
                              marginTop: "15px",
                              fontSize: "16px",
                            }}
                          >
                            {nameFormat()}
                          </p>
                        ) : null}
                      </Avatar>
                    )}
                    <Box
                      sx={{
                        padding: 1,
                        /* bgcolor: item.type === messageType.answer && "#2f2f2f", */
                        borderRadius: 3,
                      }}
                    >
                      {item.role === messageType.answer ? (
                        <div>
                          <h6 style={{ marginTop: "10px" }}>
                            {captions.project.botName}
                          </h6>
                          <ReactMarkdown
                            components={formatMarkdownComponents()}
                            children={item.content}
                          ></ReactMarkdown>
                        </div>
                      ) : (
                        <div>
                          <h6 style={{ marginTop: "10px" }}>You</h6>

                          <ReactMarkdown>{item.content}</ReactMarkdown>
                        </div>
                      )}
                    </Box>
                  </Stack>
                </Box>
              ))
            )}
          </Box>
        </Box>
        {topicClick && indexName !== "" ? (
          <Stack
            width="100%"
            alignItems="center"
            justifyContent="center"
            zIndex={3}
            position="fixed"
            bottom="0"
          >
            <Box
              padding={2}
              width="100%"
              maxWidth="md"
              zIndex={3}
              textAlign={"center"}
            >
              <FormControl fullWidth variant="outlined">
                <OutlinedInput
                  inputRef={inputRef}
                  sx={{
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderRadius: "8px",
                    },
                    color: blueGrey[500],
                  }}
                  endAdornment={
                    onRequest ? (
                      <CircularProgress size="1.5rem" />
                    ) : (
                      <IconButton disabled={question === ""}>
                        <SendOutlinedIcon
                          onClick={() => getCaptionsAnswer(question, indexName)}
                        />
                      </IconButton>
                    )
                  }
                  autoFocus
                  disabled={onRequest}
                  onKeyUp={onEnterPress}
                  value={question}
                  onChange={(e) => setQuestion(e.target.value)}
                  placeholder="Ask something..."
                />
              </FormControl>
            </Box>
          </Stack>
        ) : null}
      </Stack>
    </React.Fragment>
  );
};

export default ProjectAI;
