// ThemeToggle.js
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { toggleTheme } from "../redux/actions";
import Brightness4Icon from "@mui/icons-material/Brightness4";
import Brightness7Icon from "@mui/icons-material/Brightness7";
import { IconButton } from "@mui/material";
import { amber, blueGrey } from "@mui/material/colors";

const ThemeToggle = () => {
  const dispatch = useDispatch();
  const isDarkMode = useSelector((state) => state.darkMode);

  const handleToggleTheme = () => {
    dispatch(toggleTheme());
  };

  return (
    <IconButton
      sx={{
        m: 1,
        color: isDarkMode ? amber[50] : blueGrey[800],
      }}
      onClick={handleToggleTheme}
    >
      {isDarkMode ? <Brightness7Icon /> : <Brightness4Icon />}
    </IconButton>
  );
};

export default ThemeToggle;
