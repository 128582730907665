// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body{
  font-family: 'Sharp Sans Display No2';
}
.menu-box {
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: center;
    padding: 15px;
   margin-top: 5px;
    cursor: pointer;
    border-radius: 20px;
    
    
  }
  
  .menu-box:hover {
    color: rgb(68, 68, 184);
    text-decoration: underline;
  }
  
  .avatar {
    width: 50px; /* Set your desired size */
    height: 50px; /* Set your desired size */
    border-radius: 50%; /* Make it circular */
    margin-right: 10px;
  }
  
  .content {
    flex-grow: 1;
    font-size: 14px;
  }`, "",{"version":3,"sources":["webpack://./src/pages/general/GenericAI.css"],"names":[],"mappings":"AAAA;EACE,qCAAqC;AACvC;AACA;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,kBAAkB;IAClB,aAAa;GACd,eAAe;IACd,eAAe;IACf,mBAAmB;;;EAGrB;;EAEA;IACE,uBAAuB;IACvB,0BAA0B;EAC5B;;EAEA;IACE,WAAW,EAAE,0BAA0B;IACvC,YAAY,EAAE,0BAA0B;IACxC,kBAAkB,EAAE,qBAAqB;IACzC,kBAAkB;EACpB;;EAEA;IACE,YAAY;IACZ,eAAe;EACjB","sourcesContent":["body{\n  font-family: 'Sharp Sans Display No2';\n}\n.menu-box {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    align-self: center;\n    padding: 15px;\n   margin-top: 5px;\n    cursor: pointer;\n    border-radius: 20px;\n    \n    \n  }\n  \n  .menu-box:hover {\n    color: rgb(68, 68, 184);\n    text-decoration: underline;\n  }\n  \n  .avatar {\n    width: 50px; /* Set your desired size */\n    height: 50px; /* Set your desired size */\n    border-radius: 50%; /* Make it circular */\n    margin-right: 10px;\n  }\n  \n  .content {\n    flex-grow: 1;\n    font-size: 14px;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
