import React, { useEffect, useRef, useState } from "react";

import SendOutlinedIcon from "@mui/icons-material/SendOutlined";
import Avatar from "@mui/material/Avatar";
import { green, blueGrey, teal, amber } from "@mui/material/colors";
import ReactMarkdown from "react-markdown";
import { toast } from "react-toastify";
import "../general/GenericAI.css";
import url from "../../config/url.json";
import axios from "axios";
import captions from "../../config/captions.json";
import {
  Stack,
  Box,
  FormControl,
  OutlinedInput,
  CircularProgress,
  IconButton,
  Button,
  styled,
} from "@mui/material";

import UploadFileOutlined from "@mui/icons-material/UploadFileOutlined";

const messageType = {
  answer: "assistant",
  question: "user",
};

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const CSVAI = (props) => {
  const inputRef = useRef();
  const chatWrapperRef = useRef();
  const [fileInputKey, setFileInputKey] = useState(0);
  const fileInputRef = useRef(null);
  const [userName, setUserName] = useState("");
  const [onRequest, setOnRequest] = useState(false);
  const [question, setQuestion] = useState("");
  const [messages, setMessages] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedFileName, setSelectedFileName] = useState([]);
  const [fileResponse, setFileResponse] = useState(false);
  const [uploadErrMsg, setUploadErrMsg] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [loading, setLoading] = useState(false);

  const csv_icon = require("../../images/excel.png");

  const handleFileChange = (event) => {
    const files = event.target.files;
    const allowedFileTypes = ["application/vnd.ms-excel", "text/csv"];
    const maxSize = 5 * 1024 * 1024; // 10 MB in bytes
    const validFiles = Array.from(files).filter(
      (file) =>
        allowedFileTypes.includes(file.type) ||
        file.name.endsWith(".xls") ||
        file.name.endsWith(".xlsx")
    );

    if (validFiles.length !== 0) {
      const totalSize = Array.from(validFiles).reduce(
        (accumulator, file) => accumulator + file.size,
        0
      );
      if (totalSize > maxSize) {
        setUploadErrMsg("Total file size exceeds 5MB.");
      } else {
        setSelectedFile(validFiles);
        setSelectedFileName(validFiles.map((file) => file.name));
        //setSelectedFileName(file.name);
        setUploadErrMsg("");
      }
    } else {
      setUploadErrMsg("Please select a valid xls or csv file.");
    }

    // Check if a file is selected
    //const file = event.target.files[0];
    /* if (file) {
      // Check if the selected file is an XLS file
      if (
        allowedFileTypes.includes(file.type) ||
        file.name.endsWith(".xls") ||
        file.name.endsWith(".xlsx")
      ) {
        if (file.size > maxSize) {
          setUploadErrMsg("File exceeds 5MB.");
        } else {
          setSelectedFile(file);
          setSelectedFileName(file.name);
          setUploadErrMsg("");
        }
      } else {
        setUploadErrMsg("Please select a valid XLS or CSV file.");
        //alert("Please select a valid XLS file.");
      }
    } */
  };

  const handleUpload = () => {
    try {
      const formData = new FormData();
      for (const file of selectedFile) {
        formData.append("files", file);
      }
      const upload_url = url.dev.baseUrl + url.dev.csvUplaod;
      setLoading(true);
      axios
        .post(upload_url, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          params: { id: userEmail },
        })
        .then((response) => {
          if (response.status === 201) {
            setFileResponse(true);
            setLoading(false);
            toast.success("File uploaded successfully", {
              duration: 1000,
              position: "bottom-left",
            });
          } else {
            setLoading(false);
            toast.error("Something went  while uploading", {
              duration: 1000,
              position: "bottom-left",
            });
            setFileResponse(false);
            setSelectedFile(null);
            setSelectedFileName([]);
          }
        })
        .catch((err) => {
          setLoading(false);
          setFileResponse(false);
          setSelectedFile(null);
          setSelectedFileName([]);
          toast.error("Something went wrong", {
            duration: 1000,
            position: "bottom-left",
          });

          console.error("Error uploading files:", err);
        });
    } catch (error) {
      setLoading(false);
      setFileResponse(false);
      setSelectedFile(null);
      setSelectedFileName([]);
      toast.error("Something went wrong", {
        duration: 1000,
        position: "bottom-left",
      });

      console.error("Error uploading files:", error);
    }
  };

  const getAnswer = async () => {
    if (onRequest) return;

    const newMessages = [
      ...messages,
      {
        role: messageType.question,
        content: question,
      },
    ];

    setMessages(newMessages);
    //setMsgData(true);
    //props.onMessageCheck(true);
    setQuestion("");
    setOnRequest(true);

    try {
      const queryParams = {
        id: userEmail,
      };
      const data = {
        query: question,
      };
      const api_url = url.dev.baseUrl + url.dev.csvAI;
      const response = await axios.post(api_url, data, {
        params: queryParams,
        responseType: "arraybuffer",
      });
      /*  {"prompt" : "xyz" , "index" : "gen-index", messages: []}*/
      if (response.status === 200) {
        // Check the content type from the response headers
        const contentType = response.headers["content-type"];
        let msgType, messageContent;
        if (contentType.includes("image")) {
          //Handle image
          msgType = "image";
          const blob = new Blob([response.data], { type: "image/png" });
          const imageUrl = URL.createObjectURL(blob);
          messageContent = imageUrl;
        } else if (contentType.includes("text/html")) {
          //handle html
          msgType = "html";
          messageContent = new TextDecoder().decode(
            new Uint8Array(response.data)
          );
        } else {
          toast.error("Unsupported content type", {
            duration: 1000,
            position: "bottom-left",
          });
        }
        setOnRequest(false);
        setMessages([
          ...newMessages,
          {
            role: messageType.answer,
            type: msgType,
            content: messageContent,
          },
        ]);
      } else {
        toast.error("Something went wrong", {
          duration: 1000,
          position: "bottom-left",
        });
        setOnRequest(false);
      }
    } catch (err) {
      toast.error("Something went wrong", {
        duration: 1000,
        position: "bottom-left",
      });

      setOnRequest(false);
      return { err };
    }
  };

  const onEnterPress = (e) => {
    if (e.keyCode === 13) getAnswer();
  };
  const nameFormat = () => {
    var firstLetter = sessionStorage.getItem("name").split(" ")[0][0];
    var secondLetter = sessionStorage.getItem("name").split(" ")[1];
    if (secondLetter) {
      firstLetter = firstLetter + secondLetter[0];
    }

    return firstLetter;
  };
  useEffect(() => {
    if (inputRef.current && !onRequest) {
      inputRef.current.focus();
      inputRef.current.select();
    }
    if (props.newChatFromDrawer) {
      setMessages([]);
      setFileResponse(false);
      setSelectedFile(null);
      setSelectedFileName([]);
      //to refresh the input and trigger onChange
      setFileInputKey((prevKey) => prevKey + 1);
      if (fileInputRef.current) {
        fileInputRef.current.value = ""; // Clear the current value
      }
      props.updateFromChild(false);
    }
    setUserName(
      sessionStorage.getItem("name") === null ||
        sessionStorage.getItem("name") === "undefined"
        ? ""
        : sessionStorage.getItem("name")
    );
    setUserEmail(
      sessionStorage.getItem("email") === null ||
        sessionStorage.getItem("email") === "undefined"
        ? ""
        : sessionStorage.getItem("email")
    );
    if (chatWrapperRef.current) {
      chatWrapperRef.current.scrollTop = chatWrapperRef.current.scrollHeight;
    }
  }, [props, userName, userEmail, messages, onRequest]);

  return (
    <React.Fragment>
      <Stack
        alignItems="center"
        direction={"column"}
        justifyContent="space-between"
        sx={{ height: "100%", width: "100%" }}
      >
        <Box
          ref={chatWrapperRef}
          sx={{
            height:
              window.innerHeight < 700
                ? "64vh"
                : window.innerWidth >= 400 && window.innerWidth <= 450
                ? "70vh"
                : "65vh",
            maxHeight: "68vh",
            minHeight: "60vh",
            position: "fixed",
            zIndex: 1,
            justifyContent: "center",
            maxWidth:
              window.innerWidth >= 600 && window.innerWidth <= 900
                ? "sm"
                : "md",
            width: "100vw",
            overflowY: "auto",

            paddingTop: "10px",
            paddingBottom: "10px",
            paddingLeft: "10px",
            paddingRight: "10px",
            "&::-webkit-scrollbar, & *::-webkit-scrollbar": {
              backgroundColor: "#ddd",
              width: "5px",
            },
            "&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb": {
              borderRadius: 8,
              backgroundColor: "#eee",
              minHeight: 24,
              border: "solid #999",
            },
            "&::-webkit-scrollbar-thumb:focus, & *::-webkit-scrollbar-thumb:focus":
              {
                backgroundColor: "#959595",
              },
            "&::-webkit-scrollbar-thumb:active, & *::-webkit-scrollbar-thumb:active":
              {
                backgroundColor: "#959595",
              },
            "&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover":
              {
                backgroundColor: "#959595",
              },
            "&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner": {
              backgroundColor: "#2b2b2b",
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-end",
              maxWidth: "md",
              width: "100%",
              overflowX: "clip",
              fontSize: "15px",
            }}
          >
            {messages.length === 0 ? (
              <div>
                <center>
                  <Avatar
                    sx={{
                      // bgcolor: teal[500],
                      width: 65,
                      height: 65,
                      justifyContent: "center",
                      marginTop: "25px",
                      fontSize: "25px",
                      color: "#fff",
                    }}
                  >
                    <img alt="" src={csv_icon} width="66" />
                  </Avatar>
                </center>
                <h6 style={{ margin: "15px", fontSize: "18px" }}>
                  <center>
                    {fileResponse
                      ? captions.csv.chatText
                      : captions.csv.botText}
                  </center>
                </h6>
                {!fileResponse ? (
                  <center>
                    <Button
                      component="label"
                      variant="contained"
                      size="small"
                      style={{ margin: "10px" }}
                      disabled={loading}
                    >
                      Choose xls/csv files
                      <VisuallyHiddenInput
                        type="file"
                        //multiple
                        accept=".csv, .xls, .xlsx, application/vnd.ms-excel"
                        onChange={handleFileChange}
                        key={fileInputKey}
                        ref={fileInputRef}
                      />
                    </Button>

                    <p
                      style={{
                        color: uploadErrMsg == "" ? blueGrey[500] : amber[900],
                        fontSize: "12px",
                        textAlign: "center",
                      }}
                    >
                      {uploadErrMsg == ""
                        ? selectedFileName.map((i) => (
                            <li style={{ listStyleType: "none" }}>{i}</li>
                          ))
                        : //selectedFileName
                          uploadErrMsg}
                    </p>

                    <Button
                      component="label"
                      variant="contained"
                      startIcon={<UploadFileOutlined />}
                      size="small"
                      onClick={handleUpload}
                      disabled={
                        (selectedFile === null && !loading) ||
                        (selectedFile && loading)
                          ? true
                          : false
                      }
                      style={{ margin: "10px" }}
                    >
                      Upload
                    </Button>
                    {loading && (
                      <span>
                        <CircularProgress size={"1.2rem"} />
                        {"  Uploading"}
                      </span>
                    )}
                    <p style={{ color: blueGrey[500] }}>total max size 5MB</p>
                  </center>
                ) : null}
              </div>
            ) : (
              messages.map((item, index) => (
                <Box key={index}>
                  <Stack spacing={2} direction={"row"}>
                    {item.role === messageType.answer ? (
                      <Avatar
                        sx={{
                          //bgcolor: teal[500],
                          width: 35,
                          height: 35,
                        }}
                        style={{ marginTop: "15px" }}
                      >
                        <p
                          style={{
                            color: "white",
                            marginTop: "15px",
                            fontSize: "16px",
                          }}
                        >
                          <img alt="" src={csv_icon} width="36" />
                        </p>
                      </Avatar>
                    ) : (
                      <Avatar
                        sx={{ bgcolor: green[900], width: 35, height: 35 }}
                        style={{ marginTop: "15px" }}
                      >
                        {userName !== "" ? (
                          <p
                            style={{
                              color: "white",
                              marginTop: "15px",
                              fontSize: "16px",
                            }}
                          >
                            {nameFormat()}
                          </p>
                        ) : null}
                      </Avatar>
                    )}
                    <Box
                      sx={{
                        padding: 1,
                        /* bgcolor: item.type === messageType.answer && "#2f2f2f", */
                        borderRadius: 3,
                      }}
                    >
                      {item.role === messageType.answer ? (
                        <div>
                          <h6 style={{ marginTop: "10px" }}>
                            {captions.csv.botName}
                          </h6>
                          <div key={index}>
                            {item.type === "html" && (
                              <div
                                style={{
                                  maxWidth: "60vw",
                                  overflowX: "auto",
                                  scrollbarWidth: "none",
                                }}
                                dangerouslySetInnerHTML={{
                                  __html: item.content,
                                }}
                              ></div>
                            )}
                            {item.type === "image" && (
                              <img
                                src={item.content}
                                alt="ChatBot Image"
                                style={{
                                  maxWidth: "80%",
                                  height: "auto",
                                  display: "block",
                                }}
                              ></img>
                            )}
                          </div>
                        </div>
                      ) : (
                        <div>
                          <h6 style={{ marginTop: "10px" }}>You</h6>

                          <ReactMarkdown>{item.content}</ReactMarkdown>
                        </div>
                      )}
                    </Box>
                  </Stack>
                </Box>
              ))
            )}
          </Box>
        </Box>
        {fileResponse ? (
          <Stack
            width="100%"
            alignItems="center"
            justifyContent="center"
            zIndex={3}
            position="fixed"
            bottom="0"
          >
            <Box
              padding={2}
              width="100%"
              maxWidth="md"
              zIndex={3}
              textAlign={"center"}
            >
              <FormControl fullWidth variant="outlined">
                <OutlinedInput
                  inputRef={inputRef}
                  sx={{
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderRadius: "8px",
                    },
                    color: blueGrey[500],
                  }}
                  endAdornment={
                    onRequest ? (
                      <CircularProgress size="1.5rem" />
                    ) : (
                      <IconButton disabled={question == ""}>
                        <SendOutlinedIcon onClick={getAnswer} />
                      </IconButton>
                    )
                  }
                  autoFocus
                  disabled={onRequest}
                  onKeyUp={onEnterPress}
                  value={question}
                  onChange={(e) => setQuestion(e.target.value)}
                  placeholder="Ask something..."
                />
                <span>
                  <small>{selectedFileName[0]}</small>
                </span>
              </FormControl>
            </Box>
          </Stack>
        ) : null}
      </Stack>
    </React.Fragment>
  );
};

export default CSVAI;
