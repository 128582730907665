import React, { useEffect, useRef, useState } from "react";
import Avatar from "@mui/material/Avatar";
import { green, purple, blue } from "@mui/material/colors";
import ReactMarkdown from "react-markdown";
import { toast } from "react-toastify";
import "../general/GenericAI.css";
import url from "../../config/url.json";
import axios from "axios";
import captions from "../../config/captions.json";
import { useSelector } from "react-redux";
import {
  Stack,
  Box,
  CircularProgress,
  Button,
  ButtonGroup,
  Dialog,
  DialogTitle,
  ListItem,
  List,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import { useTheme } from "@mui/system";
const messageType = {
  answer: "assistant",
  question: "user",
};

const QuizAI = (props) => {
  const inputRef = useRef(null);
  const chatWrapperRef = useRef();
  const [userName, setUserName] = useState("");
  const [onRequest, setOnRequest] = useState(false);
  const [question, setQuestion] = useState("");
  const [messages, setMessages] = useState([]);
  const [topicClick, setTopicClick] = useState(false);
  const [userEmail, setUserEmail] = useState("");
  const [topicName, setTopicName] = useState("");
  const [topicLevel, setTopicLevel] = useState("");
  const [noOfQuest, setNoOfQuest] = useState(5);
  const [levelModalOpen, setLevelModalOpen] = useState(false);

  const isDarkMode = useSelector((state) => state.darkMode);
  const theme = useTheme();

  const quiz_icon = require("../../images/quiz master.png");

  const generateLevels = (levels) => {
    return levels.map((item) => (
      <ListItem disableGutters key={item}>
        <ListItemButton
          sx={{ textAlign: "center" }}
          divider="true"
          onClick={() => {
            setTopicLevel(item);
            setLevelModalOpen(false);
            settingTopicValues(topicName, item);
          }}
        >
          <ListItemText primary={item} sx={{ color: blue[600] }} />
        </ListItemButton>
      </ListItem>
    ));
  };

  const goToLevelModal = () => {
    return (
      <Dialog
        onClose={() => {
          setLevelModalOpen(false);
        }}
        open={levelModalOpen}
      >
        <DialogTitle>Select Difficulty Level</DialogTitle>
        <List sx={{ pt: 0 }}>{generateLevels(captions.quiz.quizLevels)}</List>
      </Dialog>
    );
  };

  const captionButtons = (id) => {
    let topics;
    switch (id) {
      case "testing":
        topics = captions.quiz.boxCaptionsTest;
        break;
      case "lang":
        topics = captions.quiz.boxCaptionsLang;
        break;
      default:
        break;
    }
    return topics.map((item) => (
      <Button
        component="label"
        style={{
          textTransform: "none",
          marginBottom: "15px",
          marginTop: "15px",
          textAlign: "center",
          boxShadow:
            "0px 3px 3px -1px rgba(0,0,0,0.1), 0px 6px 3px 0px rgba(0,0,0,0.04), 0px 1px 5px 0px rgba(0,0,0,0.06)",
        }}
        sx={{
          "&:hover": {
            backgroundColor: "#b2c6e2",
            color: isDarkMode ? "black" : "info", // Change to your desired hover color
          },
        }}
        onClick={() => {
          setTopicName(item);
          setLevelModalOpen(true);
        }}
      >
        {item}
      </Button>
    ));
  };

  const settingTopicValues = async (item, itemLevel) => {
    const apiUrl = url.dev.baseUrl + url.dev.newQuiz;

    try {
      const response = await axios.post(apiUrl, { id: userEmail, topic: item });
      var data = response.data.topic;
      if (response.status === 200 && data === item) {
        setTopicClick(true);
        setTopicName(item);
        getCaptionsAnswer(
          { topic: data, prompt: data, level: itemLevel },
          true
        );
      } else {
        toast.error("Something went wrong", {
          duration: 1000,
          position: "bottom-left",
        });

        setOnRequest(false);
      }
    } catch (err) {
      toast.error("Something went wrong", {
        duration: 1000,
        position: "bottom-left",
      });

      setOnRequest(false);
      return { err };
    }
  };

  const formatData = (data) => {
    var linkData = data.split(" ").find((word) => word.startsWith("https"));
    var dataWithoutLink = data.replace(linkData, "");
    return dataWithoutLink;
  };

  const getCaptionsAnswer = async (qData, topic) => {
    if (onRequest) return;

    const newMsgs =
      topic !== true
        ? [
            ...messages,
            {
              role: messageType.question,
              content: qData.prompt,
            },
          ]
        : [];
    setMessages(newMsgs);

    setQuestion("");
    setOnRequest(true);
    const api_url = url.dev.baseUrl + url.dev.quizAI;
    const queryParams = {
      id: userEmail,
      topic: qData.topic,
      level: qData.level,
      noOfQuestions: noOfQuest,
    };
    try {
      const result = await axios.post(
        api_url,
        JSON.stringify({ response: qData.prompt }),
        {
          params: queryParams,
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (result.status === 200 && result.data.status === "success") {
        var aiResponse = result.data.content;
        var aiResponseToJson = JSON.parse(aiResponse);
        setMessages([
          ...newMsgs,
          {
            role: messageType.answer,
            content: aiResponseToJson,
          },
        ]);
        setOnRequest(false);
      } else {
        toast.error("Something went wrong", {
          duration: 1000,
          position: "bottom-left",
        });
        setOnRequest(false);
      }
    } catch (err) {
      toast.error("Something went wrong", {
        duration: 1000,
        position: "bottom-left",
      });

      setOnRequest(false);
      return { err };
    }
  };

  const nameFormat = () => {
    var firstLetter = sessionStorage.getItem("name").split(" ")[0][0];
    var secondLetter = sessionStorage.getItem("name").split(" ")[1];
    if (secondLetter) {
      firstLetter = firstLetter + secondLetter[0];
    }

    return firstLetter;
  };
  useEffect(() => {
    if (inputRef.current && !onRequest) {
      inputRef.current.focus();
      inputRef.current.select();
    }
    if (props.newChatFromDrawer) {
      setMessages([]);
      setTopicClick(false);
      setTopicName("");
      props.updateFromChild(false);
    }
    setUserName(
      sessionStorage.getItem("name") === null ||
        sessionStorage.getItem("name") === "undefined"
        ? ""
        : sessionStorage.getItem("name")
    );
    setUserEmail(
      sessionStorage.getItem("email") === null ||
        sessionStorage.getItem("email") === "undefined"
        ? ""
        : sessionStorage.getItem("email")
    );
    if (chatWrapperRef.current) {
      chatWrapperRef.current.scrollTop = chatWrapperRef.current.scrollHeight;
      //chatWrapperRef.current.scrollIntoView({ behaviour: "smooth" });
    }
  }, [props, userName, userEmail, messages, onRequest]);

  const sendUserResponse = (userOption) => {
    getCaptionsAnswer(
      { topic: topicName, prompt: userOption, level: topicLevel },
      false
    );
  };

  return (
    <React.Fragment>
      <Stack
        alignItems="center"
        direction={"column"}
        justifyContent="space-between"
        sx={{ height: "100%", width: "100%" }}
      >
        <Box
          ref={chatWrapperRef}
          sx={{
            height:
              window.innerHeight < 700
                ? "75vh"
                : window.innerWidth >= 400 && window.innerWidth <= 450
                ? "75vh"
                : "75vh",
            maxHeight: "75vh",
            minHeight: "75vh",
            position: "fixed",
            zIndex: 1,
            justifyContent: "center",
            maxWidth:
              window.innerWidth >= 600 && window.innerWidth <= 900
                ? "sm"
                : "md",
            width: "100vw",
            overflowY: "auto",

            paddingTop: "10px",
            paddingBottom: "10px",
            paddingLeft: "10px",
            paddingRight: "10px",
            "&::-webkit-scrollbar, & *::-webkit-scrollbar": {
              backgroundColor: "#ddd",
              width: "5px",
            },
            "&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb": {
              borderRadius: 8,
              backgroundColor: "#eee",
              minHeight: 24,
              border: "solid #999",
            },
            "&::-webkit-scrollbar-thumb:focus, & *::-webkit-scrollbar-thumb:focus":
              {
                backgroundColor: "#959595",
              },
            "&::-webkit-scrollbar-thumb:active, & *::-webkit-scrollbar-thumb:active":
              {
                backgroundColor: "#959595",
              },
            "&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover":
              {
                backgroundColor: "#959595",
              },
            "&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner": {
              backgroundColor: "#2b2b2b",
            },
          }}
        >
          {messages.length !== 0 ? (
            <p
              style={{
                marginLeft: "57px",
                fontSize: "17px",
                fontStyle: "italic",
              }}
            >
              Total {noOfQuest} questions will be asked
            </p>
          ) : null}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-end",
              maxWidth: "md",
              width: "100%",
              overflowX: "clip",
              fontSize: "15px",
            }}
          >
            {messages.length === 0 ? (
              <div>
                <center>
                  <Avatar
                    sx={{
                      //bgcolor: purple[800],
                      width: 65,
                      height: 65,
                      justifyContent: "center",
                      marginTop: "25px",
                      fontSize: "25px",
                      color: "#fff",
                    }}
                  >
                    <img alt="" src={quiz_icon} width="66" />
                  </Avatar>
                </center>
                <h6 style={{ margin: "15px", fontSize: "18px" }}>
                  <center>
                    {topicClick
                      ? captions.quiz.botLoading
                      : captions.quiz.botText}
                  </center>
                </h6>
                <center>
                  {topicClick ? (
                    <CircularProgress size={"1.6rem"}></CircularProgress>
                  ) : null}
                </center>
                {onRequest ? null : (
                  <center>
                    <ButtonGroup
                      orientation="horizontal"
                      aria-label="Vertical button group"
                      variant="text"
                      color="info"
                      sx={{
                        [theme.breakpoints.down("sm")]: {
                          flexDirection: "column", // Change direction on small screens
                        },
                      }}
                    >
                      {captionButtons("testing")}
                      {goToLevelModal()}
                    </ButtonGroup>
                    <ButtonGroup
                      orientation="horizontal"
                      aria-label="Vertical button group"
                      variant="text"
                      color="info"
                      sx={{
                        [theme.breakpoints.down("sm")]: {
                          flexDirection: "column", // Change direction on small screens
                        },
                      }}
                    >
                      {captionButtons("lang")}
                      {goToLevelModal()}
                    </ButtonGroup>
                  </center>
                )}
              </div>
            ) : (
              messages.map((item, index) => (
                <Box key={index}>
                  <Stack spacing={2} direction={"row"}>
                    {item.role === messageType.answer ? (
                      <Avatar
                        sx={{
                          //bgcolor: purple[800],
                          width: 35,
                          height: 35,
                        }}
                        style={{ marginTop: "15px" }}
                      >
                        <p
                          style={{
                            color: "white",
                            marginTop: "15px",
                            fontSize: "16px",
                          }}
                        >
                          <img alt="" src={quiz_icon} width="36" />
                        </p>
                      </Avatar>
                    ) : (
                      <Avatar
                        sx={{ bgcolor: green[900], width: 35, height: 35 }}
                        style={{ marginTop: "15px" }}
                      >
                        {userName !== "" ? (
                          <p
                            style={{
                              color: "white",
                              marginTop: "15px",
                              fontSize: "16px",
                            }}
                          >
                            {nameFormat()}
                          </p>
                        ) : null}
                      </Avatar>
                    )}
                    <Box
                      sx={{
                        padding: 1,
                        /* bgcolor: item.type === messageType.answer && "#2f2f2f", */
                        borderRadius: 3,
                      }}
                    >
                      {item.role === messageType.answer ? (
                        <div>
                          <h6 style={{ marginTop: "10px" }}>
                            {captions.quiz.botName}
                          </h6>
                          {"result_for_previous_question" in item.content &&
                          item.content.explanation_for_previously_answered !==
                            "" ? (
                            <div style={{ display: "inline-block" }}>
                              <ReactMarkdown>
                                {item.content.result_for_previous_question.toString()}
                              </ReactMarkdown>
                              <p
                                style={{
                                  fontSize: "16px",
                                  marginTop: "5px",
                                  fontWeight: "bold",
                                }}
                              >
                                Explanation
                              </p>
                              <ReactMarkdown>
                                {item.content.explanation_for_previously_answered.toString()}
                              </ReactMarkdown>
                            </div>
                          ) : null}
                          {/*  {"explanation_for_previously_answered" in
                            item.content &&
                          item.content.explanation_for_previously_answered !==
                            "" ? (
                            <div>
                              <p
                                style={{
                                  fontSize: "16px",
                                  marginTop: "5px",
                                  display: "inline-block",
                                  fontWeight: "bold",
                                }}
                              >
                                Explanation
                              </p>
                              <ReactMarkdown>
                                {item.content.explanation_for_previously_answered.toString()}
                              </ReactMarkdown>
                            </div>
                          ) : null} */}
                          {"score" in item.content &&
                          item.content.score !== "" &&
                          "id" in item.content &&
                          item.content.id.toString() !== "1" ? (
                            <div>
                              <p
                                style={{
                                  fontSize: "16px",
                                  marginTop: "5px",
                                  display: "inline-block",
                                  fontWeight: "bold",
                                }}
                              >
                                <ReactMarkdown>
                                  {`Points earned:   ${item.content.score.toString()}`}
                                </ReactMarkdown>
                              </p>
                            </div>
                          ) : null}
                          {"question" in item.content &&
                          "id" in item.content ? (
                            <div>
                              <p
                                style={{
                                  fontSize: "17px",
                                  textDecorationLine: "underline",
                                  display: "inline-block",
                                  fontWeight: "bold",
                                }}
                              >
                                Question{" "}
                                {item.content.id.toString() + "/ " + noOfQuest}:
                              </p>
                              <p style={{ fontSize: "18px" }}>
                                <ReactMarkdown>
                                  {item.content.question.toString()}
                                </ReactMarkdown>
                              </p>
                            </div>
                          ) : null}
                          {"options" in item.content &&
                          item.content.options.length !== 0 ? (
                            <div>
                              <p style={{ fontStyle: "italic" }}>
                                {captions.quiz.quizOptionText}
                              </p>
                              <ButtonGroup
                                orientation="vertical"
                                aria-label="Vertical button group"
                                variant="text"
                                color="info"
                                sx={{
                                  [theme.breakpoints.down("sm")]: {
                                    flexDirection: "column", // Change direction on small screens
                                  },
                                  /* boxShadow:
                                    "0px 1px 1px -1px rgba(0,0,0,0.1), 0px 1px 5px 0px rgba(0,0,0,0.07), 0px 1px 10px 0px rgba(0,0,0,0.06)",
                                 */
                                }}
                              >
                                {item.content.options.map((btnText) => {
                                  return (
                                    <Button
                                      component="label"
                                      size="small"
                                      disabled={index !== messages.length - 1}
                                      sx={{
                                        "&.Mui-disabled": {
                                          color: isDarkMode ? "#BBB" : "#777",
                                        },

                                        justifyContent: "flex-start",
                                        "&:hover": {
                                          backgroundColor: "#ac5ce",
                                          color: isDarkMode ? "white" : "info", // Change to your desired hover color
                                          boxShadow:
                                            "0px 1px 1px -1px rgba(0,0,0,0.1), 0px 1px 15px 0px rgba(0,0,0,0.07), 0px 1px 18px 0px rgba(0,0,0,0.06)",
                                        },
                                      }}
                                      style={{
                                        textTransform: "none",
                                        justifyContent: "flex-start",
                                        marginTop: "2px",
                                      }}
                                      onClick={() => sendUserResponse(btnText)}
                                    >
                                      <ReactMarkdown>
                                        {btnText.toString()}
                                      </ReactMarkdown>
                                    </Button>
                                  );
                                })}
                              </ButtonGroup>
                            </div>
                          ) : null}
                          {"score" in item.content &&
                          "remarks" in item.content &&
                          "result_for_last_question" &&
                          "explanation_for_last_question" &&
                          "thanks_message_with_new_quiz_link" in
                            item.content ? (
                            <div>
                              <ReactMarkdown>
                                {item.content.result_for_last_question.toString()}
                              </ReactMarkdown>
                              <p
                                style={{
                                  fontSize: "16px",
                                  marginTop: "5px",
                                  fontWeight: "bold",
                                }}
                              >
                                Explanation
                              </p>
                              <ReactMarkdown>
                                {item.content.explanation_for_last_question.toString()}
                              </ReactMarkdown>
                              <h5 style={{ textDecoration: "underline" }}>
                                <ReactMarkdown>
                                  {"Your score is " +
                                    item.content.score.toString() +
                                    " out of " +
                                    noOfQuest}
                                </ReactMarkdown>
                              </h5>
                              <ReactMarkdown>
                                {item.content.remarks.toString()}
                              </ReactMarkdown>

                              {item.content.thanks_message_with_new_quiz_link
                                .toString()
                                .includes("https://chat.chtsinc.com/quiz") ? (
                                <p>
                                  {formatData(
                                    item.content.thanks_message_with_new_quiz_link.toString()
                                  )}
                                  <a href="https://chat.chtsinc.com/quiz">
                                    New Quiz
                                  </a>
                                </p>
                              ) : (
                                <ReactMarkdown>
                                  {item.content.thanks_message_with_new_quiz_link.toString()}
                                </ReactMarkdown>
                              )}
                            </div>
                          ) : null}
                        </div>
                      ) : (
                        <div>
                          <h6 style={{ marginTop: "10px" }}>You</h6>

                          <ReactMarkdown>
                            {"Selected option: " + "*" + item.content + "*"}
                          </ReactMarkdown>
                        </div>
                      )}
                    </Box>
                  </Stack>
                </Box>
              ))
            )}
          </Box>
        </Box>
        {messages.length !== 0 && onRequest ? (
          <Stack
            width="100%"
            alignItems="center"
            justifyContent="center"
            zIndex={3}
            position="fixed"
            bottom="0"
          >
            <Box
              padding={2}
              width="100%"
              maxWidth="md"
              zIndex={3}
              textAlign={"center"}
              flexDirection={"row"}
            >
              <div style={{ display: "inline-block" }}>
                <CircularProgress size="1.4rem"></CircularProgress>
                <span>
                  <sup>{" Please wait"}</sup>
                </span>
              </div>
            </Box>
          </Stack>
        ) : null}
      </Stack>
    </React.Fragment>
  );
};

export default QuizAI;
