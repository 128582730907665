// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.login-overlay {
  background: radial-gradient(#2591d0, #01304b) rgba(243, 241, 241, 0.94);
  /*  background: #2491cf; */
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: -1;
  opacity: 1;
  transition: opacity 0.2s ease-in 0.4s;
}
.login-table {
  /* background: #0682ca; */
  background: linear-gradient(#46a8e0, #02456e) rgba(243, 241, 241, 0.94);
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
}
.login-table tr {
  border-bottom: none;
}
.login-table td:nth-child(1) {
  background: #fff;
  width: 40%;
  border-radius: 5px;
  border-top-right-radius: 0%;
  border-bottom-right-radius: 0%;
}
.login-table td:nth-child(2) {
  width: 60%;
}
.login-holder {
  padding: 10px 25px;
  margin: 5px;
  width: 100%;
}

.ch-logo_login {
  margin: 5px;
  width: 160px !important;
}

.login-table {
  align-items: center;
  text-align: center;
}

element.style {
  width: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/pages/login/Main.css"],"names":[],"mappings":"AAAA;EACE,uEAAuE;EACvE,0BAA0B;EAC1B,eAAe;EACf,WAAW;EACX,YAAY;EACZ,WAAW;EACX,UAAU;EACV,qCAAqC;AACvC;AACA;EACE,yBAAyB;EACzB,uEAAuE;EACvE,kBAAkB;EAClB,2CAA2C;AAC7C;AACA;EACE,mBAAmB;AACrB;AACA;EACE,gBAAgB;EAChB,UAAU;EACV,kBAAkB;EAClB,2BAA2B;EAC3B,8BAA8B;AAChC;AACA;EACE,UAAU;AACZ;AACA;EACE,kBAAkB;EAClB,WAAW;EACX,WAAW;AACb;;AAEA;EACE,WAAW;EACX,uBAAuB;AACzB;;AAEA;EACE,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,WAAW;AACb","sourcesContent":[".login-overlay {\n  background: radial-gradient(#2591d0, #01304b) rgba(243, 241, 241, 0.94);\n  /*  background: #2491cf; */\n  position: fixed;\n  width: 100%;\n  height: 100%;\n  z-index: -1;\n  opacity: 1;\n  transition: opacity 0.2s ease-in 0.4s;\n}\n.login-table {\n  /* background: #0682ca; */\n  background: linear-gradient(#46a8e0, #02456e) rgba(243, 241, 241, 0.94);\n  border-radius: 5px;\n  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);\n}\n.login-table tr {\n  border-bottom: none;\n}\n.login-table td:nth-child(1) {\n  background: #fff;\n  width: 40%;\n  border-radius: 5px;\n  border-top-right-radius: 0%;\n  border-bottom-right-radius: 0%;\n}\n.login-table td:nth-child(2) {\n  width: 60%;\n}\n.login-holder {\n  padding: 10px 25px;\n  margin: 5px;\n  width: 100%;\n}\n\n.ch-logo_login {\n  margin: 5px;\n  width: 160px !important;\n}\n\n.login-table {\n  align-items: center;\n  text-align: center;\n}\n\nelement.style {\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
