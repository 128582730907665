import React, { useState, useEffect } from "react";
import { Container } from "react-bootstrap";
import { useMsal } from "@azure/msal-react";
import {
  blue,
  deepOrange,
  purple,
  amber,
  teal,
  cyan,
  lightGreen,
  brown,
  blueGrey,
  lightBlue,
} from "@mui/material/colors";
import { useNavigate } from "react-router-dom";
import { Avatar, IconButton, Menu, MenuItem, Divider } from "@mui/material";
import "./Home.css";
import captions from "../../config/captions.json";
import ThemeToggle from "../../components/ThemeToggle";
import AuthenticationCheck from "../../components/Authentication";
import { useSelector } from "react-redux";
import { Navbar, Row, Col } from "react-bootstrap";

const light_imageName = require("../../images/ch-logo-light.png");
const tc_icon = require("../../images/ecommerce.png");
const ai_icon = require("../../images/aiChat.png");
const xl_icon = require("../../images/excel.png");
const quiz_icon = require("../../images/quiz master.png");
const org_icon = require("../../images/org.png");
const proj_icon = require("../../images/project.png");

const dark_imageName =
  "https://cpimages.blob.core.windows.net/images/charles-hudson-logo.svg";

const Home = (props) => {
  const [userRoles, setUserRoles] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const isDarkMode = useSelector((state) => state.darkMode);
  const navigate = useNavigate();
  const { instance } = useMsal();

  const authCheck = AuthenticationCheck();

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleLogoutRedirect = () => {
    sessionStorage.clear();
    instance.logoutRedirect().catch((error) => console.log(error));
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  function goToPage(pageRoute) {
    navigate(pageRoute);
  }

  useEffect(() => {
    authCheck
      ? setUserRoles(
          sessionStorage.getItem("roles") === null ||
            sessionStorage.getItem("roles") === "undefined"
            ? []
            : JSON.parse(sessionStorage.getItem("roles"))
        )
      : setUserRoles([]);
  }, []);
  return authCheck ? (
    <React.Fragment>
      <Navbar
        data-bs-theme={isDarkMode ? "dark" : "light"}
        collapseOnSelect
        expand="lg"
        className="bg-body-tertiary"
      >
        <Container fluid>
          <Row>
            <Col className="m-2">
              <Navbar.Brand className="h6">Chat@CHTS</Navbar.Brand>
            </Col>
          </Row>
          <Row>
            <Col>
              {" "}
              <ThemeToggle />
            </Col>

            <Col>
              <IconButton
                size="small"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
                color="inherit"
              >
                <Avatar sx={{ bgcolor: amber[800] }} />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <span style={{ margin: "10px", display: "flex" }}>
                  Hi, {sessionStorage.getItem("name")}
                </span>
                <MenuItem onClick={handleLogoutRedirect}>Logout</MenuItem>
              </Menu>
            </Col>
          </Row>
        </Container>
      </Navbar>
      <Divider sx={{ border: "solid 1px", borderColor: blue[300] }}></Divider>
      <Container className="py-5">
        <div style={{ textAlign: "center" }}>
          <img
            alt=""
            src={isDarkMode ? light_imageName : dark_imageName}
            width="130"
          />
        </div>
        <div className="main-heading">
          <h2>
            <center>
              Enhance the productivity by getting tailored content and support
            </center>
          </h2>
        </div>

        <div className="sub-heading">
          <span>Applications powered by AI</span>
        </div>
        <div className="bot-section row">
          <div className="col-sm-12 col-md-6 col-lg-3">
            <div className="clickable-box" onClick={() => goToPage("/gen")}>
              <Avatar className="avatar" aria-label="recipe">
                <img alt="" src={ai_icon} width="40" />
              </Avatar>

              <div className="bot-name">{captions.gen.botTitle}</div>
            </div>
          </div>
          <div className="col-sm-12 col-md-6 col-lg-3">
            <div className="clickable-box" onClick={() => goToPage("/quiz")}>
              <Avatar className="avatar" aria-label="recipe">
                <img alt="" src={quiz_icon} width="40" />
              </Avatar>

              <div className="bot-name">{captions.quiz.botTitle}</div>
            </div>
          </div>
          {userRoles.includes("testhub") ? (
            <div className="col-sm-12 col-md-6 col-lg-4">
              <div
                className="clickable-box"
                onClick={() => goToPage("/testHub")}
              >
                <Avatar className="avatar" aria-label="recipe">
                  <img alt="" src={tc_icon} width="40" />
                </Avatar>

                <div className="bot-name">
                  {captions.manualTestHub.botTitle}
                </div>
              </div>
            </div>
          ) : null}
        </div>
        <div className="bot-section row">
          {userRoles.includes("csv") ? (
            <div className="col-sm-12 col-md-6 col-lg-3">
              <div className="clickable-box" onClick={() => goToPage("/csv")}>
                <Avatar className="avatar" aria-label="recipe">
                  <img alt="" src={xl_icon} width="40" />
                </Avatar>

                <div className="bot-name">{captions.csv.botTitle}</div>
              </div>
            </div>
          ) : null}
          {userRoles.includes("project") ? (
            <div className="col-sm-12 col-md-6 col-lg-3">
              <div
                className="clickable-box"
                onClick={() => goToPage("/analysis")}
              >
                <Avatar className="avatar" aria-label="recipe">
                  <img alt="" src={proj_icon} width="40" />
                </Avatar>

                <div className="bot-name">{captions.project.botTitle}</div>
              </div>
            </div>
          ) : null}
          {userRoles.includes("org") ? (
            <div
              className={
                userRoles.includes("project")
                  ? "col-sm-12 col-md-6 col-lg-4"
                  : "col-sm-12 col-md-6 col-lg-3"
              }
            >
              <div className="clickable-box" onClick={() => goToPage("/org")}>
                <Avatar className="avatar" aria-label="recipe">
                  <img alt="" src={org_icon} width="40" />
                </Avatar>

                <div className="bot-name">{captions.org.botTitle}</div>
              </div>
            </div>
          ) : null}
        </div>

        <div className="bot-section row"></div>
      </Container>
    </React.Fragment>
  ) : (
    navigate("/")
  );
};

export default Home;
