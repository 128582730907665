import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import store from "./redux/store";
import App from "./App";
import { PublicClientApplication, EventType } from "@azure/msal-browser";
import { msalConfig } from "./authConfig";

import "bootstrap/dist/css/bootstrap.min.css";

import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";

/**
 * MSAL should be instantiated outside of the component tree to prevent it from being re-instantiated on re-renders.
 * For more, visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/getting-started.md
 */
const msalInstance = new PublicClientApplication(msalConfig);

// Default to using the first account if no account is active on page load
if (
  !msalInstance.getActiveAccount() &&
  msalInstance.getAllAccounts().length > 0
) {
  // Account selection logic is app dependent. Adjust as needed for different use cases.
  msalInstance.setActiveAccount(msalInstance.getActiveAccount()[0]);
}

// Listen for sign-in event and set active account
msalInstance.addEventCallback((event) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
    const accessToken = event.payload.accessToken;
    const account = event.payload.account;
    sessionStorage.setItem("accessToken", accessToken);
    sessionStorage.setItem("name", account.name);
    sessionStorage.setItem(
      "roles",
      JSON.stringify(account.idTokenClaims.roles)
    );
    sessionStorage.setItem("email", account.username);
    msalInstance.setActiveAccount(account);
  }
});
//For getting user details
/* const graphEndpoint = "https://graph.microsoft.com/v1.0/me";
var accessToken = sessionStorage.getItem("accessToken");
const graphResponse = await fetch(graphEndpoint, {
  headers: {
    Authorization: `Bearer ${accessToken}`,
  },
});

const userData = await graphResponse.json();
console.log("User Data:", userData); */
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <App instance={msalInstance} />
  </Provider>
);
