import * as React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import GenericAI from "./GenericAI";
import { useNavigate } from "react-router-dom";
import AuthenticationCheck from "../../components/Authentication";
import {
  HomeOutlined,
  AccountCircleOutlined,
  RateReviewOutlined,
} from "@mui/icons-material";
import ThemeToggle from "../../components/ThemeToggle";
import { useMsal } from "@azure/msal-react";
import captions from "../../config/captions.json";

const drawerWidth = 220;
const light_imageName = require("../../images/ch-logo-light.png");
const dark_imageName =
  "https://cpimages.blob.core.windows.net/images/charles-hudson-logo.svg";

function GenChat(props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [isClosing, setIsClosing] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [newChat, setNewChat] = React.useState(false);
  const [key, setKey] = React.useState(0);
  const navigate = useNavigate();
  const isDarkMode = useSelector((state) => state.darkMode);
  const { instance } = useMsal();

  const authCheck = AuthenticationCheck();

  const handleDrawerClose = () => {
    setIsClosing(true);
    setMobileOpen(false);
  };

  const handleUpdateFromChild = (data) => {
    setNewChat(data);
  };

  const handleDrawerTransitionEnd = () => {
    setIsClosing(false);
  };

  const handleDrawerToggle = () => {
    if (!isClosing) {
      setMobileOpen(!mobileOpen);
    }
  };
  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleLogoutRedirect = () => {
    sessionStorage.clear();
    instance.logoutRedirect().catch((error) => console.log(error));
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const drawer = (
    <div>
      <Toolbar>
        <div style={{ margin: "15px", textAlign: "center" }}>
          <img
            alt=""
            src={isDarkMode ? light_imageName : dark_imageName}
            width="110"
          />
        </div>
      </Toolbar>
      <Divider />
      <List>
        <ListItem key={"New Chat"} disablePadding>
          <ListItemButton
            onClick={(e) => {
              setNewChat(true);
              setKey((prevKey) => prevKey + 1);
            }}
          >
            <ListItemIcon>
              <RateReviewOutlined />
            </ListItemIcon>
            <ListItemText primary={"New Chat"} />
          </ListItemButton>
        </ListItem>
        <ListItem key={"Home"} disablePadding>
          <ListItemButton
            onClick={() => {
              navigate("/home");
            }}
          >
            <ListItemIcon>
              <HomeOutlined />
            </ListItemIcon>
            <ListItemText primary={"Home"} />
          </ListItemButton>
        </ListItem>
      </List>
      <Divider />
      <List style={{ position: "absolute", bottom: 10 }}>
        <Divider />
        <ListItem key={"Logout"} disablePadding>
          <ListItemButton onClick={handleMenu}>
            <ListItemIcon>
              <AccountCircleOutlined />
            </ListItemIcon>
            <ListItemText primary={sessionStorage.getItem("name")} />
          </ListItemButton>
        </ListItem>
      </List>
      <Divider />
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleLogoutRedirect}>Logout</MenuItem>
      </Menu>
    </div>
  );

  // Remove this const when copying and pasting into your project.
  const container =
    window !== undefined ? () => window().document.body : undefined;

  return authCheck ? (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
          backgroundColor: isDarkMode ? "#000" : "#fff",
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: "none" } }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="div">
            {captions.gen.botHeading}
          </Typography>
          <div style={{ marginLeft: "auto" }}>
            <ThemeToggle />
          </div>
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{
          width: { sm: drawerWidth },
          flexShrink: { sm: 0 },
        }}
        aria-label="mailbox folders"
      >
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onTransitionEnd={handleDrawerTransitionEnd}
          onClose={handleDrawerClose}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              bgcolor: isDarkMode ? "#171717" : "#fff",
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              bgcolor: isDarkMode ? "#171717" : "#fff",
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
        }}
      >
        <Toolbar />
        <GenericAI
         key={key}
          newChatFromDrawer={newChat}
          updateFromChild={handleUpdateFromChild}
        />
      </Box>
    </Box>
  ) : (
    navigate("/")
  );
}

GenChat.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * Remove this when copying and pasting into your project.
   */
  window: PropTypes.func,
};

export default GenChat;
