import "react-toastify/dist/ReactToastify.css";
import { MsalProvider } from "@azure/msal-react";
import { CssBaseline } from "@mui/material";
import { ToastContainer } from "react-toastify";
import { BrowserRouter } from "react-router-dom";
import PageContainer from "./components/PageContainer";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useSelector } from "react-redux";
import { lightTheme, darkTheme } from "./config/theme";

const App = ({ instance }) => {
  const isDarkMode = useSelector((state) => state.darkMode);

  const appTheme = createTheme(isDarkMode ? darkTheme : lightTheme);

  return (
    <MsalProvider instance={instance}>
      <ThemeProvider theme={appTheme}>
        <CssBaseline />
        <ToastContainer
          position="bottom-left"
          autoClose={4000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnHover
        />
        <BrowserRouter basename={"/"} className="App">
          <PageContainer />
        </BrowserRouter>
      </ThemeProvider>
    </MsalProvider>
  );
};

export default App;
