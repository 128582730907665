import React from "react";
import { Route, Routes } from "react-router-dom";
import { IdleTimerProvider, IdleTimerConsumer } from "react-idle-timer";
import Main from "../pages/login/Main";
import Home from "../pages/home/Home";
import GenChat from "../pages/general/GenChat";
import OrgChat from "../pages/org/OrgChat";
import CSVChat from "../pages/csv/CSVChat";
import ProjectChat from "../pages/proj/ProjectChat";
import QuizChat from "../pages/quiz/QuizChat";
import ManualTestHub from "../pages/testHub/ManualTestHub";
import { useMsal } from "@azure/msal-react";

const PageContainer = () => {
  const { instance, accounts } = useMsal();

  const idleFunc = () => {
    instance
      .ssoSilent({
        redirectUri: `${window.location.origin}/login`,
      })
      .catch((error) => {
        // Handle any errors
        sessionStorage.clear();
      });
    sessionStorage.clear();
  };

  return (
    <React.Fragment>
      <IdleTimerProvider
        timeout={1000 * 3600} //expire after 3600 seconds(60 mins)
        onIdle={idleFunc}
      >
        <IdleTimerConsumer>
          {(idleTimer) => idleTimer.isIdle()}
        </IdleTimerConsumer>
      </IdleTimerProvider>

      <Routes>
        <Route path="/login" element={<Main />} />
        <Route path="/" element={<Main />} />
        <Route path="/home" element={<Home />} />
        <Route path="/gen" element={<GenChat />} />
        <Route path="/org" element={<OrgChat />} />
        <Route path="/csv" element={<CSVChat />} />
        <Route path="/analysis" element={<ProjectChat />} />
        <Route path="/quiz" element={<QuizChat />} />
        <Route path="/testHub" element={<ManualTestHub />} />
      </Routes>
    </React.Fragment>
  );
};

export default PageContainer;
