// themes.js
import { createTheme } from "@mui/material/styles";

export const lightTheme = createTheme({
  palette: {
    mode: "light",

    primary: {
      main: "#90caf9",
      backgroundColor: "#F8F9FA",
    },
    secondary: {
      main: "#131052",
    },
    background: {
      default: "#F8F9FA",
      paper: "#F8F9FA",
    },
  },
});

export const darkTheme = createTheme({
  palette: {
    mode: "dark",

    primary: {
      main: "#90caf9",
      backgroundColor: "#F8F9FA",
    },
    secondary: {
      main: "#131052",
    },
    background: {
      default: "#2B3035",
      paper: "#2B3035",
    },
  },
});
