import React, { useEffect, useRef, useState } from "react";

import SendOutlinedIcon from "@mui/icons-material/SendOutlined";
import Avatar from "@mui/material/Avatar";
import { green, deepOrange, blueGrey } from "@mui/material/colors";
import ReactMarkdown from "react-markdown";
import { toast } from "react-toastify";
import "./GenericAI.css";
import url from "../../config/url.json";
import captions from "../../config/captions.json";
import {
  Stack,
  Box,
  FormControl,
  OutlinedInput,
  CircularProgress,
  IconButton,
  Button,
  ButtonGroup,
} from "@mui/material";
import { useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";

const messageType = {
  answer: "assistant",
  question: "user",
};

const GenericAI = (props) => {
  const inputRef = useRef();
  const chatWrapperRef = useRef();
  const [userName, setUserName] = useState("");
  const [onRequest, setOnRequest] = useState(false);
  const [question, setQuestion] = useState("");
  const [messages, setMessages] = useState([]);
  const isDarkMode = useSelector((state) => state.darkMode);
  const [newChatId, setNewChatId] = useState(uuidv4());

  const ai_icon = require("../../images/aiChat.png");

  const captionButtons = () => {
    return captions.gen.boxCaptions.map((item) => (
      <Button
        component="label"
        style={{
          margin: "2px",
          textTransform: "none",
          boxShadow:
            "0px 3px 3px -1px rgba(0,0,0,0.1), 0px 6px 3px 0px rgba(0,0,0,0.04), 0px 1px 5px 0px rgba(0,0,0,0.06)",
        }}
        sx={{
          "&:hover": {
            backgroundColor: "#b2c6e2",
            color: isDarkMode ? "black" : "info", // Change to your desired hover color
          },
        }}
        onClick={() => getCaptionsAnswer(item)}
      >
        {item}
      </Button>
    ));
  };

  const getCaptionsAnswer = async (qData) => {
    if (onRequest) return;

    const newMsgs = [
      ...messages,
      {
        role: messageType.question,
        content: qData,
      },
    ];

    setMessages(newMsgs);

    setQuestion("");
    setOnRequest(true);

    const api_url = url.dev.baseUrl + url.dev.genAI;
    const queryParams = {
      id: newChatId,
    };

    const urlWithParams = `${api_url}?${new URLSearchParams(
      queryParams
    ).toString()}`;

    try {
      const response = await fetch(urlWithParams, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ prompt: qData }),
      });
      /*  {"prompt" : "xyz" , "index" : "gen-index", messages: []}*/
      if (response.status === 200) {
        const decoder = new TextDecoder();
        const reader = response.body.getReader();
        var chunks = "";
        while (true) {
          const { done, value } = await reader.read();
          if (done) {
            setOnRequest(false);
            break;
          }

          chunks += decoder.decode(value);
          setMessages([
            ...newMsgs,
            {
              role: messageType.answer,
              content: chunks.replace(/^None/, "").trim(),
            },
          ]);
        }
      } else {
        toast.error("Something went wrong", {
          duration: 1000,
          position: "bottom-left",
        });
        setOnRequest(false);
      }
    } catch (err) {
      toast.error("Something went wrong", {
        duration: 1000,
        position: "bottom-left",
      });

      setOnRequest(false);
      return { err };
    }
  };

  const onEnterPress = (e) => {
    if (e.keyCode === 13 && question !== "") getCaptionsAnswer(question);
  };

  const nameFormat = () => {
    var firstLetter = sessionStorage.getItem("name").split(" ")[0][0];
    var secondLetter = sessionStorage.getItem("name").split(" ")[1];
    if (secondLetter) {
      firstLetter = firstLetter + secondLetter[0];
    }

    return firstLetter;
  };

  useEffect(() => {
    if (inputRef.current && !onRequest) {
      inputRef.current.focus();
      inputRef.current.select();
    }
    if (props.newChatFromDrawer) {
      setMessages([]);
      props.updateFromChild(false);
    }
    setUserName(
      sessionStorage.getItem("name") === null ||
        sessionStorage.getItem("name") === "undefined"
        ? ""
        : sessionStorage.getItem("name")
    );
    if (chatWrapperRef.current) {
      chatWrapperRef.current.scrollTop = chatWrapperRef.current.scrollHeight;
    }
  }, [props, userName, messages, onRequest]);

  return (
    <React.Fragment>
      <Stack
        alignItems="center"
        direction={"column"}
        justifyContent="space-between"
        sx={{ height: "100%", width: "100%" }}
      >
        <Box
          ref={chatWrapperRef}
          sx={{
            height:
              window.innerHeight < 700
                ? "58vh"
                : window.innerWidth >= 400 && window.innerWidth <= 450
                ? "70vh"
                : "65vh",
            maxHeight: "68vh",
            minHeight: "60vh",
            position: "fixed",
            zIndex: 1,
            justifyContent: "center",
            maxWidth:
              window.innerWidth >= 600 && window.innerWidth <= 900
                ? "sm"
                : "md",
            width: "100vw",
            overflowY: "auto",
            paddingTop: "10px",
            paddingBottom: "10px",
            paddingLeft: "10px",
            paddingRight: "10px",
            "&::-webkit-scrollbar, & *::-webkit-scrollbar": {
              backgroundColor: "#ddd",
              width: "5px",
            },
            "&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb": {
              borderRadius: 8,
              backgroundColor: "#eee",
              minHeight: 24,
              border: "solid #999",
            },
            "&::-webkit-scrollbar-thumb:focus, & *::-webkit-scrollbar-thumb:focus":
              {
                backgroundColor: "#959595",
              },
            "&::-webkit-scrollbar-thumb:active, & *::-webkit-scrollbar-thumb:active":
              {
                backgroundColor: "#959595",
              },
            "&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover":
              {
                backgroundColor: "#959595",
              },
            "&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner": {
              backgroundColor: "#2b2b2b",
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-end",
              maxWidth: "md",
              width: "100%",
              overflow: "auto",
              fontSize: "15px",
            }}
          >
            {messages.length === 0 ? (
              <div>
                <center>
                  <Avatar
                    sx={{
                      //bgcolor: deepOrange[800],
                      width: 62,
                      height: 62,
                      marginTop: "10px",
                      justifyContent: "center",
                      fontSize: "25px",
                      color: "#fff",
                    }}
                  >
                    <img alt="" src={ai_icon} width="63" />
                  </Avatar>
                </center>
                <h6 style={{ marginTop: "15px", fontSize: "18px" }}>
                  <center>{captions.gen.botText}</center>
                </h6>

                {onRequest ? null : (
                  <center>
                    <ButtonGroup
                      orientation="vertical"
                      aria-label="Vertical button group"
                      variant="text"
                      color="info"
                    >
                      {captionButtons()}
                    </ButtonGroup>
                  </center>
                )}
              </div>
            ) : (
              messages.map((item, index) => (
                <Box key={index}>
                  <Stack spacing={2} direction={"row"}>
                    {item.role === messageType.answer ? (
                      <Avatar
                        sx={{ 
                          //bgcolor: deepOrange[800], 
                          width: 35, height: 35 }}
                        style={{ marginTop: "15px" }}
                      >
                        <p
                          style={{
                            color: "white",
                            marginTop: "15px",
                            fontSize: "16px",
                          }}
                        >
                          <img alt="" src={ai_icon} width="36" />
                        </p>
                      </Avatar>
                    ) : (
                      <Avatar
                        sx={{
                          bgcolor: green[900],
                          width: 35,
                          height: 35,
                        }}
                        style={{ marginTop: "15px" }}
                      >
                        {userName !== "" ? (
                          <p
                            style={{
                              color: "white",
                              marginTop: "15px",
                              fontSize: "16px",
                            }}
                          >
                            {nameFormat()}
                          </p>
                        ) : null}
                      </Avatar>
                    )}
                    <Box
                      sx={{
                        padding: 1,
                        /* bgcolor: item.type === messageType.answer && "#2f2f2f", */
                        borderRadius: 3,
                      }}
                    >
                      {item.role === messageType.answer ? (
                        <div>
                          <h6 style={{ marginTop: "10px" }}>
                            {captions.gen.botName}
                          </h6>
                          <ReactMarkdown>{item.content}</ReactMarkdown>
                        </div>
                      ) : (
                        <div>
                          <h6 style={{ marginTop: "10px" }}>You</h6>

                          <ReactMarkdown>{item.content}</ReactMarkdown>
                        </div>
                      )}
                    </Box>
                  </Stack>
                </Box>
              ))
            )}
          </Box>
        </Box>

        <Stack
          width="100%"
          alignItems="center"
          justifyContent="center"
          zIndex={3}
          position="fixed"
          bottom="0"
        >
          <Box
            padding={2}
            width="100%"
            maxWidth="md"
            zIndex={3}
            textAlign={"center"}
          >
            <FormControl fullWidth variant="outlined">
              <OutlinedInput
                inputRef={inputRef}
                sx={{
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderRadius: "8px",
                  },
                  color: blueGrey[500],
                }}
                endAdornment={
                  onRequest ? (
                    <CircularProgress size="1.5rem" />
                  ) : (
                    <IconButton disabled={question == ""}>
                      <SendOutlinedIcon
                        onClick={() => getCaptionsAnswer(question)}
                      />
                    </IconButton>
                  )
                }
                autoFocus
                disabled={onRequest}
                onKeyUp={onEnterPress}
                value={question}
                onChange={(e) => setQuestion(e.target.value)}
                placeholder="Ask something..."
              />
            </FormControl>
          </Box>
        </Stack>
      </Stack>
    </React.Fragment>
  );
};

export default GenericAI;
