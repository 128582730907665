import {
  useMsal,
} from "@azure/msal-react";

const AuthenticationCheck = () => {
  const { instance } = useMsal();

  const auth = instance.getActiveAccount() !== null ? true : false;
  return auth;
};

export default AuthenticationCheck;
