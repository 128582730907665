import React from "react";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal,
} from "@azure/msal-react";
import { loginRequest } from "../../authConfig";
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import { styled } from "@mui/material/styles";
import { amber } from "@mui/material/colors";

import "./Main.css";

const ColorButton = styled(Button)(({ theme }) => ({
  color: theme.palette.getContrastText(amber["600"]),
  backgroundColor: amber["600"],
  "&:hover": {
    backgroundColor: amber[400],
  },
}));

const Main = () => {
  const { instance } = useMsal();
  const navigate = useNavigate();

  const handleLoginRedirect = () => {
    instance.loginRedirect(loginRequest).catch((error) => console.log(error));
  };

  return (
    <React.Fragment>
      <UnauthenticatedTemplate>
        <center>
          <div className="login-overlay"></div>
          <div className="position-absolute top-50 start-50 translate-middle">
            <div className="login-table">
              <table style={{ minHeight: "400px" }}>
                <tbody>
                  <tr>
                    <td style={{ width: "30%" }}>
                      <center>
                        <img
                          className="ch-logo_login"
                          src="https://cpimages.blob.core.windows.net/images/charles-hudson-logo.svg"
                          alt="logoch"
                        />
                      </center>
                    </td>
                    <td style={{ width: "60%" }}>
                      <div className="login-holder">
                        <center>
                          <h5
                            style={{
                              textAlign: "center",
                              color: "white",
                              margin: "15px",
                            }}
                          >
                            <center>Welcome to Chat@CHTS</center>
                          </h5>
                        </center>

                        <span
                          style={{
                            textAlign: "center",
                            color: "white",
                            margin: "15px",
                            fontSize: "20px",
                          }}
                        >
                          <center>AI powered chat</center>
                        </span>

                        <center>
                          <span
                            style={{
                              color: "white",
                            }}
                          >
                            Please signin to explore
                          </span>
                        </center>
                      </div>
                      <br />
                      <center>
                        <ColorButton
                          variant="contained"
                          onClick={handleLoginRedirect}
                        >
                          Login
                        </ColorButton>
                      </center>
                      <br />
                      <br />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </center>
      </UnauthenticatedTemplate>

      <AuthenticatedTemplate>{navigate("/home")}</AuthenticatedTemplate>
    </React.Fragment>
  );
};

export default Main;
